@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Dosis", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Dosis", sans-serif;
}

code {
  font-family: "Dosis", sans-serif;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

textarea {
  resize: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.divider-x {
  position: relative;
}
.divider-x:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: black;
}

.divider-x-b {
  position: relative;
}
.divider-x-b:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: #212428;
}

.divider-x-bottom {
  position: relative;
}
.divider-x-bottom:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: #212428;
}

.divider-y {
  position: relative;
}
.divider-y:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background-color: #c0c3c6;
}

.divider-y-w {
  position: relative;
}
.divider-y-w:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background-color: white;
}

::-webkit-scrollbar {
  width: 6px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background-color: #4ed0f5;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
  border-radius: 10px;
}

.nice-dates-day:before {
  background-color: #4ed0f5 !important;
}

.nice-dates-day.-selected {
  color: #fff !important;
}

.nice-dates-day.-selected:hover:after {
  background-color: #333 !important;
}

.nice-dates-day.-today {
  color: #4ed0f5;
}

.nice-dates-popover {
}
